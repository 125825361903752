import React, { FC, useEffect, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useRouter } from 'next/router';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import { sortByProperty } from '@nploy/shared';
import { Button, ButtonIcon } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faLongArrowRight,
  faSearch,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from 'theme/palette';
import onboardingStages from 'constants/onboardingStages';
import { logAnalyticsEvent } from 'utils/analytics/event';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useJobCategories } from 'utils/swr/hooks/useJobCategories';
import { useOnboardingStage } from 'utils/swr/hooks/useOnboardingStatus';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { updateActiveState } from 'store/reducers/onboardingReducer';
import { OnboardingHeaderTitle } from 'components/Onboarding/onboarding-header-title';
import { JobCategorieList } from '../job-categories-list';
import { jobCategoriesStyles } from './job-categories.styles';
import { IJobCategories } from './job-categoroes.interface';

const MAX_CATEGORIES = 3;

export const JobCategories: FC<IJobCategories> = ({
  closeModal = () => null,
  inModal = false,
  modalOpened = false,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();
  const router = useRouter();
  const { t: tHome } = useTranslate('home');
  const { jobCategories = [] } = useJobCategories();
  const {
    user: { isAuthed },
  } = useUser();
  const { saveOnboardingStage, onboardingStage } = useOnboardingStage();
  const { isUserStudent } = useAppSelector(
    ({ onboardingReducer }) => onboardingReducer,
  );
  const {
    filtersValues: { jobCategories: savedJobCategories = [] },
    saveJobCategories,
  } = useFiltersValues();
  const { suggestedCategories } = useAppSelector(
    (state) => state.suggestionReducer,
  );
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [popularCategories, setPopularCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] =
    useState<number[]>(savedJobCategories);

  useEffect(() => {
    if (savedJobCategories && modalOpened) {
      setSelectedCategories(savedJobCategories);
    }
  }, [modalOpened, inModal]);

  const onSkip = async () => {
    if (isBrowser) {
      dispatch(updateActiveState('finished'));
      saveOnboardingStage(onboardingStages.completed);
    } else {
      await saveOnboardingStage(onboardingStages.completed);
      const redirectUrl = router.query?.redirectUrl || '/';
      await router.replace(redirectUrl as string);
    }
  };

  const handleToggleCategory = (id: number) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(
        selectedCategories.filter((categoryId) => categoryId !== id),
      );
    } else if (selectedCategories.length <= 2) {
      setSelectedCategories([...selectedCategories, id]);
    }
  };
  const triggerAnalyticsEvent = (categories) => {
    const categoriesEvents = categories.reduce((accumulator, currentId) => {
      const category = jobCategories.find(({ id }) => id === currentId);
      if (category) {
        return [...accumulator, category.event_name];
      }
      return accumulator;
    }, []);

    categoriesEvents.forEach((eventCategory) => {
      logAnalyticsEvent(`${eventCategory}_web`, {
        category: isAuthed ? 'registered_user' : 'unregistered_user',
        label: inModal ? 'job_filtering' : 'onboarding_selection',
      });
    });
  };
  const handleSearchTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearchText(e.target.value);
  };
  const validateSelection = (selection: number[]) => {
    if (!selection.length) {
      dispatch(openFeedback({ type: 'error', message: 'jobCategoriesMin' }));
      return false;
    }
    if (selection.length > MAX_CATEGORIES) {
      dispatch(openFeedback({ type: 'error', message: 'jobCategoriesMax' }));
      return false;
    }
    return true;
  };

  const handleContinue = async () => {
    if (validateSelection(selectedCategories)) {
      triggerAnalyticsEvent(selectedCategories);
      await saveJobCategories(selectedCategories);
      if (isUserStudent) {
        await onSkip();
      } else {
        dispatch(updateActiveState('salaries'));
      }
    }
  };

  const handleSave = async () => {
    if (validateSelection(selectedCategories)) {
      closeModal();
      await saveJobCategories(selectedCategories);
      triggerAnalyticsEvent(selectedCategories);
      if (listRef.current) {
        listRef.current.scrollTo({ top: 0 });
      }
    }
  };

  useEffect(() => {
    if (jobCategories.length) {
      const savedWithSuggested = onboardingStage
        ? savedJobCategories
        : Array.from(
            new Set([
              ...savedJobCategories,
              ...(suggestedCategories || []).map(({ id }) => id),
            ]),
          );

      const filteredPopularCategories = jobCategories.reduce(
        (acc, occupation) => {
          const isPopular =
            occupation.popular || savedWithSuggested.includes(occupation.id);
          if (isPopular) {
            acc.popularCategories.push(occupation);
          } else {
            acc.otherCategories.push(occupation);
          }
          return acc;
        },
        { popularCategories: [], otherCategories: [] },
      );

      const sortedPopularCategories =
        filteredPopularCategories.popularCategories.sort((a, b) => {
          if (
            savedWithSuggested.includes(a.id) &&
            !savedWithSuggested.includes(b.id)
          ) {
            return -1;
          }
          if (
            !savedWithSuggested.includes(a.id) &&
            savedWithSuggested.includes(b.id)
          ) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      setPopularCategories(sortedPopularCategories);
      setFilteredCategories(
        sortByProperty(filteredPopularCategories.otherCategories, 'name'),
      );
    }
  }, [jobCategories, savedJobCategories, suggestedCategories, onboardingStage]);

  useEffect(() => {
    if (suggestedCategories?.length && !selectedCategories?.length) {
      setSelectedCategories(suggestedCategories.map(({ id }) => id));
    }
  }, [suggestedCategories]);

  return (
    <Container sx={jobCategoriesStyles.container} component="section">
      <Box pt={[1, inModal ? 0 : 5]} pb={3}>
        <Grid container item>
          {inModal ? (
            <Grid item container justifyContent="flex-end" component="div">
              <ButtonIcon
                onClick={closeModal}
                sx={jobCategoriesStyles.buttonIcon}
              >
                <FontAwesomeIcon
                  icon={faTimes as IconProp}
                  color={palette.darkerGrey.main}
                  size="sm"
                />
              </ButtonIcon>
            </Grid>
          ) : null}

          <Grid container item xs={12} md={3} xl={2} />
          {/* Title */}
          <OnboardingHeaderTitle
            title={tHome('whatIsYourField')}
            subtitle={tHome('youCanSelectUpTo')}
          />

          {/* Search */}
          <Grid container item xs={9} sm={6} md={3} xl={2} margin="auto">
            <TextField
              value={searchText}
              onChange={handleSearchTextChange}
              fullWidth
              placeholder={t('search')}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <Box p={1}>
                    <FontAwesomeIcon
                      icon={faSearch as IconProp}
                      color={palette.text.primary}
                      size="lg"
                    />
                  </Box>
                ),
                sx: {
                  '& input': {
                    color: palette.text.primary,
                    fontWeight: 'bold',
                  },
                },
              }}
            />
          </Grid>

          {/* Categories */}
          <Grid container item xs={12} md={7} margin="auto">
            <Box
              ref={listRef}
              sx={{
                ...jobCategoriesStyles.categoriesWrapper,
                ...(!inModal && { height: '500px' }),
              }}
            >
              {popularCategories.length && (
                <JobCategorieList
                  categoriesData={popularCategories}
                  handleToggleCategory={handleToggleCategory}
                  title="Most Popular"
                  selectedCategories={selectedCategories}
                  searchTerm={searchText}
                />
              )}
              {filteredCategories.length && (
                <JobCategorieList
                  categoriesData={filteredCategories}
                  handleToggleCategory={handleToggleCategory}
                  title="All"
                  selectedCategories={selectedCategories}
                  searchTerm={searchText}
                  sxWrapper={{
                    mt: 3,
                  }}
                />
              )}
            </Box>
            {((searchText && !filteredCategories.length) ||
              !jobCategories.length) && (
              <Grid container item xs={12}>
                <Typography
                  variant="body1"
                  component="h2"
                  color="textSecondary"
                >
                  {tHome('noCategoriesFound')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container item xs={12} justifyContent="center">
        <Box sx={jobCategoriesStyles.buttonContainer}>
          <Button
            endIcon={
              <FontAwesomeIcon
                icon={faLongArrowRight as IconProp}
                color={palette.common.black}
                size="lg"
              />
            }
            onClick={inModal ? handleSave : handleContinue}
          >
            {inModal ? t('save') : t('continue')}
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};
