import { useContext } from 'react';
import useSWR from 'swr';
import {
  ICandidateCountryFullDto,
  IEmploymentLevelDto,
  IEmploymentTypeDto,
  ISeniorityLevelDto,
} from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';
import { StaticFiltersContext } from 'utils/context';
import { fetchData } from '../helpers/fetchData';

export const getFilters = async () => {
  const data = await fetchData('list/general-info');
  return data;
};

interface FiltersType {
  countries: ICandidateCountryFullDto[];
  employ_levels: IEmploymentLevelDto[];
  employ_types: IEmploymentTypeDto[];
  seniority_levels: ISeniorityLevelDto[];
  workModels: IWorkModel[];
}

export const useFilters = () => {
  const staticFilters = useContext(StaticFiltersContext);

  const { data } = useSWR<FiltersType>('list/general-info', fetchData, {
    initialData: staticFilters,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    filters:
      data ||
      ({
        countries: [],
        seniority_levels: [],
        employ_levels: [],
        employ_types: [],
        workModels: [],
      } as FiltersType),
  };
};
