import theme from 'theme/theme';

export const jobCategoriesListStyles = {
  wrapper: {
    maxWidth: '730px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      px: '30px',
    },
  },
  title: {
    '&.MuiTypography-root': {
      textAlign: 'left',
      marginRight: 'auto',
      mb: 2,
      color: '#767B7F',
    },
  },
};
