import palette from 'theme/palette';
import theme from 'theme/theme';

const borderWidth = 2.5;

export const jobCategoriesItemStyles = {
  card: {
    width: '100%',
    p: 2.4,
    textDecoration: 'none',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: '72px',
    marginBottom: 1,
    borderRadius: '10px',
    border: 'solid',
    borderWidth,
    borderColor: palette.common.white,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      px: '5px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      px: '5px',
    },
    '& .MuiTypography-body1': {
      fontSize: '1em',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.paleGrey.light,
      borderColor: palette.paleGrey.light,
      boxShadow:
        '0px 8px 14px -2px rgba(50,50,93,0.25),0px 5px 9px -2px rgba(0,0,0,0.15)',
      transition: '500ms',
    },
  },
  contentWrapper: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 17,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5vw',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 5,
    },
  },
  selectedCard: {
    borderColor: palette.primary.main,
    '&:hover': {
      borderColor: palette.primary.main,
    },
  },
  title: {
    fontSize: '1em',
    width: '100%',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      display: '-webkit-box',
      webkitLineClamp: '1',
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '34px',
    width: '34px',
    borderRadius: 5,
    mr: 5,
    [theme.breakpoints.down('xs')]: {
      mr: 1,
      height: 38,
      width: 38,
    },
  },
  checkedContainerPlaceholder: {
    height: 32,
    width: 32,
  },
  checkedContainer: {
    height: 32,
    width: 32,
    backgroundColor: palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'absolute',
    right: '3%',
  },
};
