import React, { FC } from 'react';
import Image from 'next/image';
import { Box, Paper, Typography } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import useTranslate from 'utils/i18n/useTranslate';
import icons from '../jobCategoriesIcons';
import { IJobCategoriesItem } from './job-categories-item.interface';
import { jobCategoriesItemStyles } from './job-categories-item.styles';

export const JobCategoriesItem: FC<IJobCategoriesItem> = ({
  category: { name, icon, backgroundColor },
  isSelected,
  onClick,
}) => {
  const { t } = useTranslate();
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Paper
      component="article"
      elevation={2}
      onClick={handleClick}
      sx={{
        ...jobCategoriesItemStyles.card,
        ...(isSelected && jobCategoriesItemStyles.selectedCard),
      }}
    >
      <Box sx={jobCategoriesItemStyles.contentWrapper}>
        <Box>
          <Box
            style={{
              backgroundColor:
                backgroundColor || palette.pageBackgroundGrey.main,
            }}
            sx={jobCategoriesItemStyles.iconContainer}
          >
            <Image
              src={icons[icon] || icons.default}
              alt={`${name} ${t('img.jobCategoryIcon')}`}
              title={name}
              width={24}
              height={24}
            />
          </Box>
        </Box>
        <Typography component="h3" sx={jobCategoriesItemStyles.title}>
          {name}
        </Typography>
      </Box>

      {isSelected && (
        <Box sx={jobCategoriesItemStyles.checkedContainer}>
          <FontAwesomeIcon
            icon={faCheck as IconProp}
            color={palette.common.black}
          />
        </Box>
      )}
    </Paper>
  );
};
