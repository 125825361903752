import { useContext } from 'react';
import StaticCategoriesContext from 'utils/context/static-categories-context';
import { fetchData } from '../helpers/fetchData';

export const getJobCategories = async (locale: string) =>
  fetchData('list/job-occupations', {
    all: 1,
    locale,
  });

export const useJobCategories = () => {
  const staticCategories = useContext(StaticCategoriesContext);

  return {
    jobCategories: staticCategories,
  };
};
