import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { JobCategoriesItem } from '../job-categories-item';
import { IJobCategoriesListIterface } from './job-categories-list.interface';
import { jobCategoriesListStyles } from './job-categories-list.styles';

export const JobCategorieList: FC<IJobCategoriesListIterface> = ({
  categoriesData,
  searchTerm,
  selectedCategories,
  handleToggleCategory,
  title,
  sxWrapper,
}) => {
  const [categories, setCategories] = useState(categoriesData);

  useEffect(() => {
    if (!searchTerm?.trim()) {
      setCategories(categoriesData);
    } else {
      const filtered = categoriesData.filter(({ name }) =>
        name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
      );
      setCategories(filtered);
    }
  }, [searchTerm, categoriesData]);

  return (
    <Box
      sx={{ ...jobCategoriesListStyles.wrapper, ...(sxWrapper && sxWrapper) }}
    >
      <Typography sx={jobCategoriesListStyles.title}>{title}</Typography>
      {categories.map(({ id, ...category }) => (
        <JobCategoriesItem
          key={`${id}-${category.slug}`}
          category={category}
          onClick={() => handleToggleCategory(id)}
          isSelected={selectedCategories.includes(id)}
        />
      ))}
    </Box>
  );
};
