import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { IHeaderTitle } from './onboarding-header-title.interface';
import { onboardingHeaderTitleStyles } from './onboarding-header-title.styles';

export const OnboardingHeaderTitle: FC<IHeaderTitle> = ({
  subtitle,
  title,
}) => (
  <Grid item xs={12} md={5} xl={7} margin="auto">
    <Box textAlign="center">
      <Typography variant="h2" component="h2">
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="h2"
        color="textSecondary"
        sx={onboardingHeaderTitleStyles.infoText}
      >
        {subtitle}
      </Typography>
    </Box>
  </Grid>
);
