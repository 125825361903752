import useSWR from 'swr';
import { axios } from '@nploy/shared';
import onboardingStages from 'constants/onboardingStages';
import {
  getFromLocalStorage,
  localStorageKeys,
  setToLocalStorage,
} from 'utils/localStorage';
import { useAppDispatch } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import isWindow from '../../isWindow';
import { fetchData } from '../helpers/fetchData';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';
import { useUser } from './useUser';

const getUnAuthedOnboardingStage = () =>
  getFromLocalStorage(localStorageKeys.onboardingStage);

const fetcher = async (url: string) => {
  if (shouldFetchIfHasToken()) {
    const data = await fetchData<{ status: boolean }>(url);
    return data.status ? onboardingStages.completed : null;
  }
  return getUnAuthedOnboardingStage();
};

export const useOnboardingStage = () => {
  const dispatch = useAppDispatch();
  const {
    user: { isAuthed },
  } = useUser();

  const { data, error, mutate } = useSWR(
    'settings/onboarding-status',
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );
  const saveOnboardingStage = async (stage: string) => {
    mutate(stage, false);
    setToLocalStorage(localStorageKeys.onboardingStage, stage);
    if (stage === onboardingStages.completed && isAuthed) {
      try {
        await axios.post('settings/onboarding-status');
      } catch (err) {
        dispatch(openFeedback({ type: 'error', message: err.message }));
      } finally {
        mutate();
      }
    }
  };

  return {
    onboardingStage: data,
    loading:
      isWindow() &&
      shouldFetchIfHasToken() &&
      typeof data === 'undefined' &&
      !error,
    mutate,
    saveOnboardingStage,
  };
};
