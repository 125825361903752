import { createContext } from 'react';
import {
  ICandidateCountryFullDto,
  IEmploymentLevelDto,
  IEmploymentTypeDto,
  ISeniorityLevelDto,
} from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';

export const StaticFiltersContext = createContext<{
  countries: ICandidateCountryFullDto[];
  employ_levels: IEmploymentLevelDto[];
  employ_types: IEmploymentTypeDto[];
  seniority_levels: ISeniorityLevelDto[];
  workModels: IWorkModel[];
}>({
  countries: [],
  seniority_levels: [],
  employ_levels: [],
  employ_types: [],
  workModels: [],
});
