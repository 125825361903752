import React, { FC } from 'react';
import { ExperienceLabelFormat, SliderWithPanel } from '@nploy/ui/web';
import useTranslate from 'utils/i18n/useTranslate';
import { IExperienceSlider } from './experience-slider.interface';

export const ExperienceSlider: FC<IExperienceSlider> = ({
  maxValue,
  handleValueChange,
  value,
  levels,
  minValue = 0,
}) => {
  const { t } = useTranslate('home');
  return (
    <SliderWithPanel
      min={minValue}
      max={maxValue}
      value={value}
      onChange={handleValueChange}
      step={1}
      levels={levels}
      LabelFormatComponent={(val: number) => (
        <ExperienceLabelFormat
          value={val}
          levels={levels}
          translations={{
            yearsOfExperience: t('filters.experience.yearsOfExperience'),
          }}
        />
      )}
    />
  );
};
