import { axios } from '@nploy/shared';

export const getCountryFromIP = async () => {
  try {
    const { data } = await axios.get('countryFromIP');
    return data?.data;
  } catch (error) {
    return null;
  }
};

export default getCountryFromIP;
