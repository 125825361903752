import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';

export const jobCategoriesStyles = {
  container: {
    px: 0,
  },
  buttonIcon: {
    boxShadow: shadows[1],
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  infoText: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.2),
  },
  categoriesWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
    height: '70vh',
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    [theme.breakpoints.down(750)]: {
      px: theme.spacing(2),
    },
    '&::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: '5px',
      backgroundColor: palette.paleGrey.light,
    },

    '&::-webkit-scrollbar': {
      width: 5,
      backgroundColor: palette.paleGrey.light,
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: palette.info.main,
    },
    [theme.breakpoints.down('xs')]: {
      height: '60vh',
      pl: 1,
      pr: 1,
    },
  },
};
