import accounting_finance_and_statistics from "../../public/images/icons/job-categories/accounting_finance_and_statistics.svg";
import administration_and_secretarial from "../../public/images/icons/job-categories/administration_and_secretarial.svg";
import architecture_and_construction from "../../public/images/icons/job-categories/architecture_and_construction.svg";
import business_management_and_consulting from "../../public/images/icons/job-categories/business_management_and_consulting.svg";
import catering_hospitality_tourism from "../../public/images/icons/job-categories/catering_hospitality_tourism.svg";
import charity_and_voluntary_work from "../../public/images/icons/job-categories/charity_and_voluntary_work.svg";
import client_customer_service from "../../public/images/icons/job-categories/client_customer_service.svg";
import compliance_risk from "../../public/images/icons/job-categories/compliance_risk.svg";
import design_and_creative_arts from "../../public/images/icons/job-categories/design_and_creative_arts.svg";
import education_curriculum_teaching from "../../public/images/icons/job-categories/education_curriculum_teaching.svg";
import engineers_and_technical_occupations from "../../public/images/icons/job-categories/engineers_and_technical_occupations.svg";
import hr_and_recruitment from "../../public/images/icons/job-categories/hr_and_recruitment.svg";
import insurance from "../../public/images/icons/job-categories/insurance.svg";
import it_and_technology from "../../public/images/icons/job-categories/it_and_technology.svg";
import legal_and_councelling from "../../public/images/icons/job-categories/legal_and_councelling.svg";
import logistic_and_transportation from "../../public/images/icons/job-categories/logistic_and_transportation.svg";
import marketing_and_communications_pr from "../../public/images/icons/job-categories/marketing_and_communications_pr.svg";
import media_journalism_and_publishing from "../../public/images/icons/job-categories/media_journalism_and_publishing.svg";
import healthcare_and_therapy from "../../public/images/icons/job-categories/healthcare_and_therapy.svg";
import music_entertainment_and_event_management from "../../public/images/icons/job-categories/music_entertainment_and_event_management.svg";
import production_and_manufacturing from "../../public/images/icons/job-categories/production_and_manufacturing.svg";
import property_and_real_estate from "../../public/images/icons/job-categories/property_and_real_estate.svg";
import sales_and_trade from "../../public/images/icons/job-categories/sales_and_trade.svg";
import science_and_research from "../../public/images/icons/job-categories/science_and_research.svg";
import security from "../../public/images/icons/job-categories/security.svg";

const icons = {
  accounting_finance_and_statistics,
  administration_and_secretarial,
  architecture_and_construction,
  business_management_and_consulting,
  catering_hospitality_tourism,
  charity_and_voluntary_work,
  client_customer_service,
  compliance_risk,
  design_and_creative_arts,
  education_curriculum_teaching,
  engineers_and_technical_occupations,
  hr_and_recruitment,
  insurance,
  it_and_technology,
  legal_and_councelling,
  logistic_and_transportation,
  marketing_and_communications_pr,
  media_journalism_and_publishing,
  healthcare_and_therapy,
  music_entertainment_and_event_management,
  production_and_manufacturing,
  property_and_real_estate,
  sales_and_trade,
  science_and_research,
  security,
  default: charity_and_voluntary_work
};

export default icons;
